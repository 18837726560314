<template>
  <div class="page">
    <!-- 搜索条件表单 -->
    <el-form class="ser_form" size="small" label-width="68px">
      <el-form-item class="el_form_item" label="运单编号">
        <el-input
          class="el_input"
          v-model="form.truck_tord_num"
          placeholder="货源运单编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="上游编号">
        <el-input
          class="el_input"
          v-model="form.out_trade_num"
          placeholder="运单上游编号"
          clearable
        ></el-input>
      </el-form-item>
      <!-- <el-form-item class="el_form_item" label="车主手机">
        <el-input
          class="el_input"
          v-model="form.truck_owner_tel"
          placeholder="车主手机"
          clearable
        ></el-input>
      </el-form-item> -->
      <el-form-item class="el_form_item" label="司机手机">
        <el-input
          class="el_input"
          v-model="form.driver_tel"
          placeholder="司机手机"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item class="el_form_item" label="车牌号">
        <el-input
          class="el_input"
          v-model="form.truck_plate_num"
          placeholder="货源运单编号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="货物名称">
        <el-input
          class="el_input"
          v-model="form.cargo_name"
          placeholder="货物名称搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="装货地">
        <el-cascader
          class="el_input"
          v-model="form.case_cities"
          @change="ser_para_case_cities_change"
          placeholder="装货地省市区"
          :options="ser_para.cities"
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item class="el_form_item" label="到货地">
        <el-cascader
          class="el_input"
          v-model="form.aim_cities"
          @change="ser_para_aim_cities_change"
          placeholder="到货地省市区"
          :options="ser_para.cities"
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item class="el_form_item" label="etc状态">
        <el-select class="el_input" v-model="form.etc_status" clearable>
          <el-option label="不限" value=""></el-option>
          <el-option label="etc未开票" value="1"></el-option>
          <el-option label="etc已开票" value="2"></el-option>
          <el-option label="etc开票失败" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="el_form_item elfrom_item" label="开出时间">
        <div class="block">
          <el-date-picker
            v-model="date_value"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            value-format="timestamp"
          >
          </el-date-picker>
        </div>
      </el-form-item>
      <el-form-item label-width="0" id="button">
        <el-button type="primary" @click="cargo_ser">查询</el-button>
        <el-button type="primary" @click="ser_para_clear">清空条件</el-button>
        <el-button type="primary" @click="kaichu_batch" >批量开出</el-button>
         <el-button type="primary" @click="fill_invoice" >填写发票代码号</el-button>
        <el-button type="success" @click="data_exportFile">导出本页</el-button>
        <el-button type="success" @click="whole_exportFile">全部导出</el-button>
      </el-form-item>
    </el-form>
    <el-radio-group
      v-model="trans_status"
      style="margin-bottom: 30px"
      @change="handleClick()"
    >
      <el-radio-button label="1">全部</el-radio-button>
      <el-radio-button label="7">可开票</el-radio-button>
      <el-radio-button label="8">开票中</el-radio-button>
      <el-radio-button label="9">已开票</el-radio-button>
    </el-radio-group>
    <div class="total_info">
      <span>共:{{ list.total }}条</span>
      <span>总开票金额:{{ total_invoice }}元</span>
      <span>总运费:{{ total_freight }}元</span>
      <span>总现金:{{ total_cash }}元</span>
      <span>总油费:{{ total_fuel }}元</span>
      <span>总服务费:{{ total_service }}元</span>
    </div>
    <!-- 表格 -->
    <div class="tab_height">
      <el-table
        :data="list.data"
        :border="true"
        :stripe="true"
        size="small"
        v-loading="list.loading"
        @selection-change="select_table"
      >
        <el-table-column type="selection" width="40" align="center">
        </el-table-column>
        <el-table-column label="抬头" width="180px">
          <template slot-scope="scope">
            <div>{{ scope.row.title }}</div>
          </template>
        </el-table-column>
        <el-table-column label="税号" width="180px">
          <template slot-scope="scope">
            <div>{{ scope.row.dp }}</div>
          </template>
        </el-table-column>
        <el-table-column label="运单编号">
          <template slot-scope="scope">
            <div>{{ scope.row.truck_tord_num }}</div>
          </template>
        </el-table-column>
          <el-table-column label="发票代码">
          <template slot-scope="scope">
            <div>{{ scope.row.cost_info.invoice_code }}</div>
          </template>
        </el-table-column>
         <el-table-column label="发票号码">
          <template slot-scope="scope">
            <div>{{ scope.row.cost_info.invoice_number }}</div>
          </template>
        </el-table-column>
        <el-table-column label="总运费" width="230px">
          <template slot-scope="scope">
            <div>总计：{{ scope.row.freight_total }}</div>
            <div>现金：{{ scope.row.cash_total }}</div>
            <div>油费：{{ scope.row.fuel_total }}</div>
            <div>手续费：{{ scope.row.service_charge }}</div>
          </template>
        </el-table-column>
        <el-table-column label="申请开票金额" width="230px">
          <template slot-scope="scope">
            <div>{{ scope.row.invoice_total }}</div>
            <div v-if="scope.row.invoiced_time && scope.row.invoiced_time != 0">
              开出时间:{{ scope.row.invoiced_time }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="支付记录" width="230px">
          <template slot-scope="scope">
            <div class="tab_line_item">支付记录</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              @click="kaichu(scope.row)"
              v-if="trans_status == 8"
              size="mini"
              >开出</el-button
            >
            <!-- <el-button
              type="primary"
              @click="shuihao(scope.row)"
              v-if="trans_status == 8"
              size="mini"
              >填写税号</el-button
            > -->
            <!-- <el-button
              type="primary"
              id="etc"
              @click="etc_invoice(scope.row)"
              size="mini"
              >etc自动开票</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-backtop target=".page" :bottom="70" :right="120"></el-backtop>
    <!-- 分页 -->
    <el-pagination
      class="margin-top-xl"
      @size-change="page_size_change"
      @current-change="page_current_change"
      :current-page="page.p"
      :page-sizes="[10, 200, 500, 1000]"
      :page-size="page.num"
      :total="list.total * 1"
      layout="total, sizes,prev, pager, next, jumper"
    ></el-pagination>

  <el-dialog top="1vh" title="批量开出" width="1050px" :visible.sync="is_show_in_page" @close="invoice_close">
    <el-form class="form_ordinary" label-width="165px">
      <div class="tab1_inner">
    <el-form-item class="el_form_item elfrom_item" label="开出时间">
        <div class="block">
              <el-date-picker
                v-model="date_value1"
                type="datetimerange"
                :picker-options="pickerOptions"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right"
                value-format="timestamp"
              >
              </el-date-picker>
            </div>
          </el-form-item>
      </div>
       <div class="tab1_inner">
        <el-form-item class="el_form_item" label="发票号码"  style="width: 450px; margin: 10px 0px">
        <el-input
           type="textarea"
           style="width:268px"
           placeholder="请输入发票号码"
           v-model="invoice_number_list">
        </el-input>
      </el-form-item>
       <el-form-item class="el_form_item" label="发票代码号"  style="width: 450px; margin: 10px 0px">
        <el-input
           type="textarea"
           style="width:268px"
           placeholder="请输入发票代码号"
           v-model="invoice_code_list">
        </el-input>
         </el-form-item>
      </div>
     <div class="btn_area" style="text-align: center;">
			<el-button type="primary" @click="batch_confirm">确认</el-button>
		</div>
    </el-form>
  </el-dialog>
  </div>
</template>

<script>
import xlsx from "xlsx";
import { regionData, CodeToText } from "element-china-area-data";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      //搜索条件参数
      ser_para: {
        cargo_type: [], //货物类型
        cities: regionData, //省市区数据
      },
      change_data: "", //勾选数据
      date_value: "", //时间选择
      date_value1:'',
      //搜索条件
      form: {
        etc_status: "", //etc开票状态
        //truck_cargo_num: "", //货源编号
        out_trade_num: "", //上游编号
        //truck_owner_tel: "", //车主手机号
        driver_tel: "", //司机手机号
        truck_plate_num: "", //车牌号
        cargo_name: "", //货物名称
        //cargo_type: "", //货物性质
        case_prov: "", //装货地省份
        case_city: "", //装货地城市
        case_county: "", //装货地县区
        aim_prov: "", //到货地省份
        aim_city: "", //到货地城市
        aim_county: "", //到货地县区
        //pay_status: "", //支付状态
        //invoice_status: "", //开票状态
        //settle_status: "", //结算状态

        invoiced_time_start: "", // 开始时间
        invoiced_time_end: "", // 结束

        case_cities: null, //装货地省市县
        aim_cities: null, //到货地省市县
      },
      is_show_in_page:false, //开出弹窗
      invoice_number_list:'',
      invoice_code_list:'',

      trans_status: "1", //切换 1全部/2可开票/3开票中/4已开票
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      //发票税号事件
      invoiced_time_start:'',
      invoiced_time_end:'',
      //全部导出数组
      excel_arr:[],
      //总计数据
      total_cash: "",
      total_freight: "",
      total_fuel: "",
      total_invoice: "",
      total_service: "",
      //表格数据
      list: {
        loading: false, //loading特效
        data: [], //列表数据
        total: 0, //列表总记录数
      },
      batchConfirm_list: [],
      //页码信息
      page: {
        need_num: 1,
        num: 10,
        p: 1,
      },
    };
  },
  created() {
    //读取货源
    this.get_page_data();
  },
  methods: {
    //弹窗关闭
    invoice_close(){
      this.date_value1 = "",
      this.invoice_number_list = "",
      this.invoice_code_list = ""
    },
    //填写发票税号
    batch_confirm(){
      if (this.date_value1) {
        this.invoiced_time_start = parseInt(this.date_value1[0] / 1000);
        this.invoiced_time_end = parseInt(this.date_value1[1] / 1000);
        this.get_page_data();
          } else {
            (this.invoiced_time_start = ""),
              (this.invoiced_time_end = ""),
              this.get_page_data();
          }
          //调用接口
          this.$my.net.req({
            data: {
              mod:"truck_tord_real_time",
              ctr:"invoice_code_add",
              invoiced_time_start:this.invoiced_time_start,
              invoiced_time_end:this.invoiced_time_end,
              invoice_number_list:this.invoice_number_list,
              invoice_code_list:this.invoice_code_list
            },
            callback: (data) => {
              this.$my.other.msg({
                  str: "操作成功",
                  type: "success",
                });
                this.is_show_in_page = false
                  //刷新本页
                this.get_page_data();
                },
          });
    },
    // 发票代码号填写
    fill_invoice(){
        this.is_show_in_page = true
    },
    kaichu_batch() {
      //批量开出
      if (this.batchConfirm_list.length == 0) {
        this.$message({
          type: "info",
          message: "当前未勾选数据",
        });
        return;
      }
      this.$my.other.confirm({
        content: "确定开出?",
        confirm: () => {
          //调用接口
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "invoice_done_admin_new",
              truck_tord_nums: this.batchConfirm_list,
            },
            callback: (data) => {
              //提醒
              this.$my.other.msg({
                str: "操作成功",
                type: "success",
              });

              //刷新本页
              this.get_page_data();
            },
          });
        },
      });
    },
    //tap切换读取数据
    handleClick() {
      this.page.p = 1;
      this.get_page_data();
    },

    //发货地省市县选择器
    ser_para_case_cities_change(event) {
      this.form.case_prov = CodeToText[event[0]];
      this.form.case_city = CodeToText[event[1]];
      this.form.case_county = CodeToText[event[2]];
    },

    //到货地省市县选择器
    ser_para_aim_cities_change(event) {
      this.form.aim_prov = CodeToText[event[0]];
      this.form.aim_city = CodeToText[event[1]];
      this.form.aim_county = CodeToText[event[2]];
    },
    // 勾选数据
    select_table(item) {
      var truck_tord_num = [];
      var ids = [];
      item.forEach((item) => {
        truck_tord_num.push(item.truck_tord_num);
        ids.push(item.id);
      });
      this.change_data = ids;
      this.batchConfirm_list = truck_tord_num;
    },
    kaichu(info) {
      //单个开出
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "invoice_done_admin_new",
          truck_tord_nums: [info.truck_tord_num],
        },
        callback: (data) => {
          this.$my.other.msg({
            type: "success",
            str: "操作成功",
          });
        },
      });
    },
    //etc自动开票
    etc_invoice(item) {
      var ids = [];
      ids.push(item.id);
      this.$my.net.req({
        data: {
          mod: " truck_tord_real_time",
          ctr: "etc_invoice_apply",
          ids,
        },
        callback: (data) => {
          console.log(data);
        },
      });
    },
    shuihao() {
      //填写税号
    },
    //撤回
    invoice_revoke(item) {
      //询问
      this.$my.other.confirm({
        content: "点击确定撤回开票",
        confirm: () => {
          //调用接口
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "tord_invoice_revoke_by_admin",
              truck_tord_num: item.truck_tord_num,
              ra_num: item.ra_num,
            },
            callback: (data) => {
              //刷新本页
              this.get_page_data();
            },
          });
        },
      });
    },

    //驳回
    invoice_refuse(item) {
      //询问
      this.$my.other.confirm({
        content: "点击确定驳回开票申请",
        confirm: () => {
          //调用接口
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "tord_invoice_refuse_by_admin",
              truck_tord_num: item.truck_tord_num,
              ra_num: item.ra_num,
            },
            callback: (data) => {
              //刷新本页
              this.get_page_data();
            },
          });
        },
      });
    },

    //确定开出
    invoice_done(item) {
      //询问
      this.$my.other.confirm({
        content: "确定开出?",
        confirm: () => {
          //调用接口
          this.$my.net.req({
            data: {
              mod: "truck_tord_real_time",
              ctr: "tord_invoice_done_by_admin",
              truck_tord_num: item.truck_tord_num,
              ra_num: item.ra_num,
            },
            callback: (data) => {
              //提醒
              this.$my.other.msg({
                str: "操作成功",
                type: "success",
              });

              //刷新本页
              this.get_page_data();
            },
          });
        },
      });
    },

    //清空查询条件
    ser_para_clear() {
      this.form = {
        //truck_cargo_num: "", //货源编号
        out_trade_num: "", //上游编号
        //truck_owner_tel: "", //车主手机号
        driver_tel: "", //司机手机号
        truck_plate_num: "", //车牌号
        cargo_name: "", //货物名称
        //cargo_type: "", //货物性质
        case_prov: "", //装货地省份
        case_city: "", //装货地城市
        case_county: "", //装货地县区
        aim_prov: "", //到货地省份
        aim_city: "", //到货地城市
        aim_county: "", //到货地县区
        pay_status: "", //支付状态
        //invoice_status: "", //开票状态
        //settle_status: "", //结算状态

        invoiced_time_start: "", // 开始时间
        invoiced_time_end: "", // 结束

        case_cities: null, //装货地省市县
        aim_cities: null, //到货地省市县
      };
      this.date_value = "";
      this.get_page_data();
    },
    //勾选提示框
    data_exportFile() {
      if (this.change_data.length == 0) {
        this.$my.other.confirm({
          content: "当前未勾选数据,是否全部导出？",
          confirm: () => {
            this.excel_out(this.change_data);
          },
        });
      } else {
        this.excel_out(this.change_data);
      }
    },
    //生成csv
			make_csv(data){
        console.log(data)
				var shunxu=[
          {key:"id",cname:"系统id"},
					{key:"truck_tord_num",cname:"运单编号"},
					{key:"out_trade_num",cname:"商户运单号",},
					{key:"start_time",cname:"建单时间"},
					{key:"shipper_name",cname:"货主名称"},
					{key:"shipper_tel",cname:"货主手机号"},
					{key:"title",cname:"抬头"},
					{key:"shuihao",cname:"税号"},
          {key:"invoice_money",cname:"总开票额"},
          {key:"tax_num",cname:"发票号码"},
					{key:"cargo_volume",cname:"货物体积(方)"},
					{key:"cargo_weight",cname:"货物重量(吨)"},
					{key:"cargo_name",cname:"货物名称"},
          {key:"driver_name",cname:"司机姓名"},
					{key:"driver_tel",cname:"司机电话"},
					{key:"truck_type_name",cname:"车型"},
					{key:"truck_type_name2",cname:"细分类型"},
					{key:"truck_plate_num",cname:"车牌号"},
          {key:"case_prov",cname:"发货地省份"},
					{key:"case_city",cname:"发货地城市"},
          {key:"case_county",cname:"发货地县区"},
					{key:"case_info",cname:"发货地详细地址"},
					{key:"creat_time",cname:"发车时间"},
					{key:"aim_prov",cname:"到货地省份"},
					{key:"aim_city",cname:"到货地城市"},
					{key:"aim_county",cname:"到货地县区"},
					{key:"aim_info",cname:"到货地详细地址"},
					{key:"arrived_time",cname:"到货时间"},
          {key:"mark",cname:"备注"},
					{key:"freight_total",cname:"总运费"},
          {key:"cash_total",cname:"现金"},
					{key:"fuel_total",cname:"油费"},
					{key:"service_charge",cname:"服务费"},
					{key:"invoiced_time_text",cname:"开出时间"},
					{key:"tax_status_text",cname:"上报状态"},
					{key:"indicatorStatus_text",cname:"回调信息"},
          {key:"ready_for_upload_text",cname:"是否军品"},
				],str="";
				for(var a in shunxu){
					str+=shunxu[a].cname+",";
				}
				str+="\n";
				for(var i=0;i<data.length;i++){
					for(var a in shunxu){
						str+=`${data[i][shunxu[a].key]}\t,`;
					}
					str+='\r\n';
				}

				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "开票数据.csv";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

        // 加载完毕
        let loadingInstance = Loading.service(this.options);
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
			},
    //全部导出
    whole_exportFile() {
      Loading.service(this.options);
      this.page.num = 3000;
      this.$my.net.req({
        take_over_control: 1,
        data: {
          mod: "truck_tord_real_time",
          ctr: "tord_payed_list_by_admin_excelout",
          ...this.form,
          ...this.page,
          trans_status: this.trans_status,
        },
        callback: (data) => {
          if (data.code != 1) {
            this.page.p++;
            data.msg.list.forEach(item=>{
              item.tax_num = item.tax_num.split(',').join(' ')
              if(item.invoiced_time>0){
                item.invoiced_time_text = this.$my.other.totime(item.invoiced_time);
              }else item.invoiced_time_text = "无"
              
              if(item.report_info){
                item.indicatorStatus_text = ''
                if(item.report_info.notify){
                  item.report_info.notify.forEach(date=>{
                    if(date.indicatorStatus ==1){
                      item.indicatorStatus_text += date.name+'|'
                    }
                  })
                }
              }else{
                 item.indicatorStatus_text = '无'
              }
              //上报状态
                switch (item.tax_status) {
                  case "1":
                    item.tax_status_text = "未上报";
                    break;
                  case "2":
                    item.tax_status_text = "一次已传";
                    break;
                  case "3":
                    item.tax_status_text = "二次已传";
                    break;
                  case "4":
                    item.tax_status_text = "三次已传";
                    break;
                }
                 if(!item.tax_status){
                   item.tax_status_text='无'
                 }
                //是否军品
                if(item.ready_for_upload ==3){
                  item.ready_for_upload_text = "是";
                }else{
                  item.ready_for_upload_text = "否";
                }
              this.excel_arr.push(item)
            })
            this.whole_exportFile();
          } else {
            this.make_csv(this.excel_arr)
            //清空数组
					  this.excel_arr = []
            this.page.p = 1;
            this.page.num = 10;
          }
        },
      });
    },
    //导出
    excel_out(ids) {
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "tord_payed_list_by_admin_excelout",
          ...this.form,
          ...this.page,
          ids,
          trans_status: this.trans_status,
        },
        callback: (data) => {
          data.list.forEach((item) => {
            if(item.invoiced_time>0){
              item.invoiced_time_text = this.$my.other.totime(item.invoiced_time);
            }else item.invoiced_time_text = "无"
            if(item.report_info){
              item.indicatorStatus_text = ''
              if(item.report_info.notify){
                item.report_info.notify.forEach(date=>{
                  if(date.indicatorStatus ==1){
                    item.indicatorStatus_text += date.name+'|'
                  }
                })
              }
            }else{
              item.indicatorStatus_text = '无'
            }
             //上报状态
            switch (item.tax_status) {
              case "1":
                item.tax_status_text = "未上报";
                break;
              case "2":
                item.tax_status_text = "一次已传";
                break;
              case "3":
                item.tax_status_text = "二次已传";
                break;
              case "4":
                item.tax_status_text = "三次已传";
                break;
            }
            if(!item.tax_status){
              item.tax_status_text='无'
            }
            //是否军品
            if(item.ready_for_upload ==3){
              item.ready_for_upload_text = "是";
            }else{
              item.ready_for_upload_text = "否";
            }
          });
          this.make_csv(data.list)
        },
      });
    },

    //搜索
    cargo_ser() {
      if (this.date_value) {
        this.form.invoiced_time_start = parseInt(this.date_value[0] / 1000);
        this.form.invoiced_time_end = parseInt(this.date_value[1] / 1000);
        this.get_page_data();
      } else {
        (this.form.invoiced_time_start = ""),
          (this.form.invoiced_time_end = ""),
          this.get_page_data();
      }
    },

    //页宽变化
    page_size_change(num) {
      //置入页码
      this.page.num = num;

      //读取
      this.get_page_data();
    },

    //页码变化
    page_current_change(p) {
      //置入页码
      this.page.p = p;

      //读取
      this.get_page_data();
    },

    //获取货源列表
    get_page_data() {
      //置为加载中
      this.list.loading = true;
      //读取数据
      this.$my.net.req({
        take_over_control: 1,
        data: {
          mod: "truck_tord_real_time",
          ctr: "tord_invoice_apply_list_admin_new",
          ...this.form,
          ...this.page,
          trans_status: this.trans_status,
        },
        callback: (data) => {
          if (data.code == 1) {
            this.list.loading = false;
            this.list.total = 0;
            this.total_cash = 0;
            this.total_freight = 0;
            this.total_fuel = 0;
            this.total_invoice = 0;
            this.total_service = 0;
            this.list.data = [];
          } else {
            //加载完毕
            data.msg.list.forEach((item) => {
              if (item.invoiced_time && item.invoiced_time != 0) {
                item.invoiced_time = this.$my.other.totime(item.invoiced_time);
              }
            });
            this.list.loading = false;

            //总数
            this.list.total = data.msg.max;
            this.total_cash = data.msg.total.total_cash;
            this.total_freight = data.msg.total.total_freight;
            this.total_fuel = data.msg.total.total_fuel;
            this.total_invoice = data.msg.total.total_invoice;
            this.total_service = data.msg.total.total_service;

            //渲染
            this.list.data = data.msg.list;
          }
        },
      });
      if (this.list.data) {
        this.list.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  height: calc(100% - 40px);
  overflow: auto;
}
.ser_form {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}
.tab_height {
  //height: calc(100% - 134px);
}
.el_form_item {
  width: 275px;
}
.el_input {
  width: 94%;
}
.btn_left {
  margin: 0;
}
.total_info {
  text-align: right;
  font-size: 12px;
  margin-bottom: 10px;
  margin-right: 1px;
  color: #606266;
  span {
    margin-left: 10px;
  }
}
#button {
  margin-left: 220px;
}
#etc {
  margin-top: 10px;
  margin-left: -2px;
}
.tab1_inner{
  display: flex;
}
</style>